<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Setting Proposal
    </hero-bar>

    <section class="section is-main-section">

    <div class="card has-table has-mobile-sort-spaced">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="account-multiple" custom-size="default" />
            Informasi
          </p>
          <router-link slot="right" to="/setting_akademik_edit">
            <b-field class="card-header-icon">
              <b-button size="is-small"
                  icon-left="pencil">
                    Edit
              </b-button>
            </b-field>
          </router-link>
        </header>
        <div class="card-content" style="padding-left: 16px; line-height: 32px;">
          <p>Nama Prodi : <span v-if="setting[0]">{{ setting[0].prodi }}</span></p>
          <p>Fakultas : <span v-if="setting[0]">{{ setting[0].fakultas }}</span></p>
          <p>Universitas : <span v-if="setting[0]">{{ setting[0].universitas }}</span></p>
          <p>Konsentrasi : <span v-if="konsentrasi">{{ arrToStr(konsentrasi) }}</span></p>
          <p>Kelas : <span v-if="kelas">{{ arrToStr(kelas) }}</span></p>
          <p>Semester Akademik : <span v-if="semester">{{ arrToStr(semester) }}</span></p>
          <p>Tahun Akademik : <span v-if="tahun_akademik">{{ arrToStr(tahun_akademik) }}</span></p>
          <p>Jenjang : <span v-if="jenjang">{{ arrToStr(jenjang) }}</span></p>
          <p>Buka : <span v-if="setting[0]">{{ setting[0].buka }}</span></p>
          <p>Tutup :<span v-if="setting[0]">{{ setting[0].tutup }}</span></p>
        </div>
    </div>

      <div class="card has-table has-mobile-sort-spaced">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="account-multiple" custom-size="default" />
            Proposal
          </p>
          <b-field class="card-header-icon">
              <b-input placeholder="Search..."
                  type="search"
                  size="is-small"
                  icon="magnify"
                  v-model="searchWord"
                  @input="search(searchWord)">
              </b-input>
          </b-field>
          <router-link slot="right" to="/setting_proposal/create">
            <b-field class="card-header-icon">
              <b-button size="is-small"
                  icon-left="plus" class="button is-primary">
                    Tambah
              </b-button>
            </b-field>
          </router-link>

        </header>
        <div class="card-content">
          <modal-box
            :is-active="isModalActive"
            :trash-object-name="trashObjectName"
            @confirm="trashConfirm"
            @cancel="trashCancel"
          />
          <b-table
            :checked-rows.sync="checkedRows"
            :loading="isLoading"
            :paginated="paginated"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            default-sort="name"
            :data="data"
          >
          <b-table-column label="Jenjang" field="jenjang" sortable v-slot="props">
              {{ props.row.jenjang }}
            </b-table-column>
            <b-table-column label="Semester Akademik" field="semester akademik" sortable v-slot="props">
              {{ props.row.semester }}
            </b-table-column>
            <b-table-column label="Tahun Akademik" field="tahun akademik" sortable v-slot="props">
              {{ props.row.tahun }}
            </b-table-column>
            <b-table-column label="Status" field="status" sortable v-slot="props">
              <!-- {{ props.row.status }} -->
              <span @click="updateStatus(props.row.id,props.row.status)">
                <b-field>
                  <b-switch v-model="props.row.status"
                      true-value="1"
                      false-value="0"
                      >
                  </b-switch>
                </b-field>
              </span>
          </b-table-column>
            <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
              <div class="buttons is-right">
                <!-- <router-link :to="{name:'setting_proposal.edit', params: {id: props.row.id}}" class="button is-small is-primary">
                  <b-icon icon="account-edit" size="is-small"/>
                </router-link> -->
                <button class="button is-small is-danger" type="button" @click.prevent="trashModal(props.row)">
                  <b-icon icon="trash-can" size="is-small"/>
                </button>
              </div>
            </b-table-column>

            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import axios from 'axios'
import ModalBox from '@/components/ModalBox'

export default {
  name: 'Tables',
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      oriData: [],
      data: [],
      searchWord: null,
      isLoading: false,
      paginated: false,
      perPage: 10,
      checkedRows: [],
      konsentrasi: [],
      setting: [],
      kelas: [],
      semester: [],
      tahun_akademik: [],
      jenjang: []
    }
  },
  components: {
    HeroBar,
    TitleBar,
    ModalBox
  },
  computed: {
    titleStack () {
      return ['Home', 'Data Proposal']
    },
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }

      return null
    }
  },
  mounted () {
    this.getData()
    axios
      .get('/setting_proposal')
      .then((r) => {
        this.setting = r.data.data
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  },
  methods: {
    arrToStr (arr) {
      let tmp = ''

      for (const item of arr) {
        tmp += item.name
        tmp += ', '
      }

      tmp = tmp.slice(0, -2)

      return tmp
    },
    getData () {
      this.isLoading = true
      axios
        .get('/akademik')
        .then((r) => {
          this.isLoading = false
          if (r.data && r.data.data) {
            if (r.data.data.length > this.perPage) {
              this.paginated = true
            }
            this.data = r.data.data
            this.oriData = r.data.data
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      axios
        .get('/kbk')
        .then((r) => {
          this.konsentrasi = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      axios
        .get('/kelas')
        .then((r) => {
          this.kelas = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      axios
        .get('/semester')
        .then((r) => {
          this.semester = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      axios
        .get('/tahun_akademik')
        .then((r) => {
          this.tahun_akademik = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      axios
        .get('/jenjang')
        .then((r) => {
          this.jenjang = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    search (word) {
      if (word === null || word === '') {
        this.getData()
        return false
      }

      console.log(`search for ${word}`)
      const result = this.oriData.filter(obj => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(obj)) {
          // console.log(`${word} in ${value} ${String(value).toLowerCase().indexOf(word)}`)
          if (String(value).toLowerCase().indexOf(word.toLowerCase()) >= 0) {
            return true
          }
        }
      })

      this.data = result
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false

      axios
        .delete(`/akademik/${this.trashObject.id}`)
        .then((r) => {
          console.log(r.data)
          if (r.data.status === 'ok') {
            this.$buefy.toast.open({
              message: r.data.message,
              queue: false
            })
            this.$router.go()
          } else {
            this.$buefy.toast.open({
              message: r.data.message,
              type: 'is-danger',
              queue: false
            })
          }
        })
        .catch((e) => {
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger',
            queue: false
          })
        })
    },
    trashCancel () {
      this.isModalActive = false
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })
      if (r.data.status === 'ok') {
        // this.$router.go()
      }
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    updateStatus (id, status) {
      status = parseInt(status)
      const data = {
        status: !status
      }
      console.log('update status')
      this.isLoading = true

      axios
        .post(`/akademik/update-status/${id}`, data)
        .then(this.handleResponse)
        .catch(this.handleError)
    }
  }

}
</script>
